import React from "react"
import { graphql } from "gatsby"

import OneMapa from "../components/poster/single-mapa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Mapa = ({ data }) => {
  const mapa = data.mapyYaml
  const mapaImg = mapa.pictures[0].childImageSharp.original
  function posterClass() {
    if (mapaImg.height > mapaImg.width) {
      return "pionowy"
    } else if (mapaImg.height === mapaImg.width) {
      return "full"
    } else {
      return "poziomy"
    }
  }
  return (
    <Layout>
      <SEO
        title={mapa.name}
        description={mapa.desc[0]}
        image={mapa.pictures[2].childImageSharp.original.src}
      />

      <div>
        <OneMapa
          name={mapa.name}
          year={mapa.year}
          desc={mapa.desc}
          plakatDrukInfo={mapa.drukInfo}
          plakatFormat={mapa.format}
          imgSrc={mapa.pictures}
          posterClass={posterClass}
        />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    mapyYaml(fields: { slug: { eq: $slug } }) {
      name
      drukInfo
      year
      desc
      format
      pictures {
        childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
    }
  }
`

export default Mapa
